<header
  class="register-base-modal-header"
  [ngClass]="{
    'flex items-center justify-between flex-row-reverse': !showIcon
  }"
>
  <div class="register-base-modal-header__top-bar">
    <span class="register-base-modal-header__heading-icon" *ngIf="showIcon">
      <svg-icon
        svgClass="register-base-modal-header__heading-icon__icon"
        [src]="svgSrc"
      ></svg-icon>
    </span>

    <div class="register-base-modal-header__buttons-group">
      <liv-button
        [iconOnly]="true"
        rounded="lg"
        icon="assets/icons/close.svg"
        variant="ghost"
        (buttonClick)="onClose()"
      >
      </liv-button>
    </div>
  </div>

  <h2 *ngIf="title" class="register-base-modal-header__title w-full">
    {{ title }}
  </h2>
</header>
