import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Data } from '../../models/liv-portfolio-response-protocol.model';
import { MediaModel } from 'src/app/shell/models/media.model';
import {
  SetMediaAction,
  SetSeriesAction,
  SetTeacherAction
} from './teacher.action';
import { patch } from '@ngxs/store/operators';

export interface ProfessorModel {
  id: number;
  nickname: string;
  description: string;
  step: number;
  externalProfessorId?: number;
  email?: string;
  avatar: Data<MediaModel> | null;
}

export interface SerieModel {
  id: number;
  externalProfessorId: number;
  name: string;
  externalSegmentId: number;
  color?: string;
  quantity: number;
}

export interface TeacherStateModel {
  professor: ProfessorModel;
  series: SerieModel[];
}

const TEACHER_STATE_TOKEN = new StateToken<TeacherStateModel>('teacher');

@State<TeacherStateModel>({
  name: TEACHER_STATE_TOKEN,
  defaults: {
    professor: null,
    series: []
  }
})
@Injectable()
export class TeacherState {
  @Selector() static teacher({ professor }: TeacherStateModel): ProfessorModel {
    return professor;
  }

  @Selector() static media({ professor }: TeacherStateModel): Data<MediaModel> {
    return professor?.avatar || null;
  }

  @Selector() static series({ series }: TeacherStateModel): SerieModel[] {
    return series;
  }

  @Action(SetTeacherAction) setTeacher(
    { patchState }: StateContext<TeacherStateModel>,
    { payload }: SetTeacherAction
  ): void {
    patchState({
      professor: payload
    });
  }

  @Action(SetSeriesAction) setSeries(
    { patchState }: StateContext<TeacherStateModel>,
    { payload }: SetSeriesAction
  ): void {
    patchState({
      series: payload
    });
  }

  @Action(SetMediaAction) setMidia(
    { setState }: StateContext<TeacherStateModel>,
    { payload }: SetMediaAction
  ): void {
    setState(
      patch({
        professor: patch({
          avatar: payload
        })
      })
    );
  }
}
