import { MediaModel } from 'src/app/shell/models/media.model';
import { Data } from '../../models/liv-portfolio-response-protocol.model';
import { ProfessorModel, SerieModel } from './teacher.state';

export class SetTeacherAction {
  static readonly type = '[Teacher] Set teacher data model';
  constructor(public payload: ProfessorModel) {}
}

export class SetMediaAction {
  static readonly type = '[Teacher] Set media teacher';
  constructor(public payload: Data<MediaModel>) {}
}

export class SetSeriesAction {
  static readonly type = '[Teacher] Set series';
  constructor(public payload: SerieModel[]) {}
}
