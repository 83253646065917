import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarComponent } from './avatar/avatar.component';
import { ButtonComponent } from './button/button.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SpinnerComponent } from './spinner/spinner.component';
import { ModalComponent } from './modal/modal.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { WindowResizeService } from 'src/app/shell/services/window-resize.service';
import { RegisterBaseHeaderModalComponent } from './register-base-header-modal/register-base-header-modal.component';
import { ShareModalComponent } from './share-modal/share-modal.component';
import { PreviewModalComponent } from './preview-modal/preview-modal.component';

@NgModule({
  declarations: [
    AvatarComponent,
    ButtonComponent,
    SpinnerComponent,
    ModalComponent,
    RegisterBaseHeaderModalComponent,
    ShareModalComponent,
    PreviewModalComponent
  ],
  exports: [
    AvatarComponent,
    ButtonComponent,
    SpinnerComponent,
    RegisterBaseHeaderModalComponent
  ],
  imports: [CommonModule, AngularSvgIconModule, SharedModule],
  providers: [WindowResizeService]
})
export class CommonsModule {
  static forRoot(): ModuleWithProviders<CommonsModule> {
    return {
      ngModule: CommonsModule,
      providers: []
    };
  }
}
