<div class="py-6 px-8 flex flex-col gap-6">
  <div>
    <h2 class="!mb-4 font-semibold text-2xl text-neutral-low-dark">
      Compartilhe o Portfólio!
    </h2>
    <p class="text-sm">
      Chegou a hora de enviar para as famílias? Baixe a imagem, copie o link e
      envie no canal de comunicação da sua escola! Vai fazer evento presencial
      para a entrega? Baixe o QR Code, imprima e disponibilize para as famílias!
    </p>
  </div>
  <div class="grid grid-cols-[1fr_auto_1fr] px-6">
    <div class="flex flex-col !gap-3">
      <div class="flex !gap-1 items-center text-neutral-low-medium">
        <span class="text-xs font-medium">Baixe aqui a imagem</span>
        <svg-icon
          svgClass="w-4 h-4 fill-current"
          src="assets/icons/arrow-down.svg"
        ></svg-icon>
      </div>
      <div class="group relative overflow-hidden rounded-lg">
        <img
          class="w-full aspect-square object-cover"
          [src]="shareMedia?.formats?.small?.url"
          alt=""
        />
        <button
          type="button"
          class="absolute top-0 left-0 invisible opacity-0 w-full h-full bg-brand-primary-pure bg-opacity-80 text-neutral-hight-pure flex flex-col gap-2 justify-center items-center transition-[opacity] group-hover:!visible group-hover:!opacity-100"
          (click)="handleDownloadContent()"
        >
          <span class="font-medium text-sm">Baixar imagem</span>
          <svg-icon
            svgClass="w-6 h-6 fill-current"
            src="assets/icons/arrow-circle-down.svg"
          ></svg-icon>
        </button>
      </div>
    </div>
    <hr
      class="border-r border-solid border-neutral-low-light h-3/5 mx-8 self-center"
    />
    <div class="flex flex-col !gap-3">
      <div class="flex !gap-1 items-center text-neutral-low-medium">
        <span class="text-xs font-medium">Baixe aqui o QR Code</span>
        <svg-icon
          svgClass="w-4 h-4 fill-current"
          src="assets/icons/arrow-down.svg"
        ></svg-icon>
      </div>
      <div
        class="group relative overflow-hidden rounded-lg flex-1 bg-brand-primary-lightest bg-opacity-30 px-2 !py-3 flex flex-col items-center !gap-2"
      >
        <span class="text-xs font-bold text-neutral-low-dark text-center">{{
          portfolioTitle
        }}</span>
        <div class="mx-3">
          <img
            id="qrCode"
            class="aspect-square object-cover rounded-lg flex-1"
            [src]="qrCodeUrl"
            alt=""
          />
        </div>
        <button
          type="button"
          class="absolute top-0 left-0 invisible opacity-0 w-full h-full bg-brand-secondary-pure bg-opacity-90 text-neutral-hight-pure flex flex-col gap-2 justify-center items-center transition-[opacity] group-hover:!visible group-hover:!opacity-100"
          (click)="handleDownloadQRCode()"
        >
          <span class="font-medium text-sm">Baixar QR Code</span>
          <svg-icon
            svgClass="w-6 h-6 fill-current"
            src="assets/icons/arrow-circle-down.svg"
          ></svg-icon>
        </button>
      </div>
    </div>
  </div>
  <div
    class="flex justify-between !pt-5 border-t border-solid border-neutral-low-light"
  >
    <liv-button
      variant="secondary"
      iconPlacement="left"
      icon="assets/icons/link.svg"
      buttonText="Copiar link"
      rounded="lg"
      size="lg"
      (buttonClick)="handleShareClick()"
    ></liv-button>
    <liv-button
      variant="primary"
      buttonText="Concluído"
      rounded="lg"
      size="lg"
      (buttonClick)="handleCloseModal()"
    ></liv-button>
  </div>
</div>
