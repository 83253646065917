<div
  data-cid="liv-modal"
  class="!px-8 !py-6 rounded-lg w-[calc(100vw_-_1.5rem)]"
  [ngStyle]="{ 'background-color': options?.backgroundColor }"
  [ngClass]="{
    'shadow-lg': options?.backgroundColor !== 'transparent'
  }"
  [ngClass]="containerClass"
>
  <div class="flex flex-col">
    <div class="flex items-start justify-between">
      <button
        *ngIf="options?.isCloseButtonVisible"
        class="flex items-center justify-center relative ml-auto text-neutral-low-medium hover:text-neutral-low-dark transition-colors duration-150"
        (click)="onClose()"
      >
        <ng-icon name="heroXMark" size="24" class="text-current"></ng-icon>
      </button>
    </div>
    <div
      *ngIf="component"
      [ngClass]="{
        '!mt-6': options?.isCloseButtonVisible
      }"
    >
      <ng-container
        *ngComponentOutlet="component; injector: dynamicComponentInjector"
      ></ng-container>
    </div>
  </div>
</div>
