<button
  data-cid="liv-button"
  class="liv-button"
  [type]="type"
  (click)="buttonClick.emit()"
  [disabled]="disabled || isLoading"
  [title]="!iconOnly ? buttonText : ''"
  [ngClass]="dynamicClass"
>
  <ng-container *ngIf="!iconOnly">
    <span *ngIf="!isLoading">{{ buttonText }}</span>
    <liv-spinner *ngIf="!icon && isLoading" size="md"> </liv-spinner>
  </ng-container>

  <ng-container *ngIf="icon">
    <svg-icon
      [svgClass]="'liv-button__icon' + (iconClass ? iconClass : '')"
      *ngIf="!!icon && !isLoading"
      [src]="icon"
    ></svg-icon>
    <liv-spinner *ngIf="isLoading" size="md"> </liv-spinner>
  </ng-container>
</button>
