import { Pipe, PipeTransform } from '@angular/core';
import { convertHexToRGBA } from '../helpers/convert-colors';

const defaultColor = '#000';
const defaultOpacity = 1;

@Pipe({
  name: 'color'
})
export class ColorPipe implements PipeTransform {
  transform(color: string, opacity?: string): string {
    return convertHexToRGBA(
      color ?? defaultColor,
      opacity ? Number(opacity) : defaultOpacity
    );
  }
}
