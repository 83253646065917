<div
  class="liv-avatar"
  [ngClass]="{
    'liv-avatar--rounded-full': variant === 'circle',
    'liv-avatar--rounded-md': variant === 'square',
    'liv-avatar--bg': !initials || initials,
    'liv-avatar--sm': size === 'sm',
    'liv-avatar--md': size === 'md',
    'liv-avatar--lg': size === 'lg',
    'liv-avatar--xl': size === 'xl',
    'liv-avatar--2xl': size === '2xl',
    'liv-avatar--3xl': size === '3xl',
    'liv-avatar--4xl': size === '4xl'
  }"
>
  <ng-container *ngIf="src || initials; else placeholder">
    <ng-container *ngIf="src; else userInitials">
      <img
        [src]="src"
        [alt]="alt ?? ''"
        class="liv-avatar-image"
        loading="lazy"
        (error)="onError()"
      />
    </ng-container>
    <ng-template #userInitials>
      <span
        class="liv-avatar-initials"
        [ngClass]="{
          'liv-avatar-initials--text-sm': size === 'sm',
          'liv-avatar-initials--text-md': size === 'md',
          'liv-avatar-initials--text-lg': size === 'lg',
          'liv-avatar-initials--text-xl': size === 'xl',
          'liv-avatar-initials--text-2xl': size === '2xl',
          'liv-avatar-initials--text-3xl': size === '3xl',
          'liv-avatar-initials--text-4xl': size === '4xl'
        }"
        >{{ initials }}</span
      >
    </ng-template>
  </ng-container>

  <ng-template #placeholder>
    <svg class="liv-avatar-placeholder" fill="currentColor" viewBox="0 0 24 24">
      <path
        d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
      />
    </svg>
  </ng-template>
</div>
