<div
  class="!py-5 !pr-5 h-full grid grid-rows-[auto_1fr] !gap-3 items-stretch md:grid-rows-1 md:grid-cols-[1fr_1fr_auto] md:!gap-0 md:!pr-0"
>
  <div
    class="flex justify-between items-center !pl-5 !gap-4 md:!p-8 md:flex-col md:justify-end md:!gap-0 md:items-start"
  >
    <h2
      class="font-semibold text-neutral-low-dark text-base md:text-3xl md:!mb-6"
      [ngStyle]="{
        'text-wrap': 'balance'
      }"
    >
      Veja como está ficando o Portfólio da sua turma!
    </h2>
    <p
      *ngIf="isDesktop"
      class="text-neutral-low-dark"
      [ngStyle]="{
        'text-wrap': 'balance'
      }"
    >
      Você sabia? Muitas famílias vão visualizar o Portfólio que você está
      criando através do celular.
    </p>
    <img
      *ngIf="isDesktop"
      class="!mt-10 relative -left-8"
      [src]="coverUrl"
      alt=""
    />

    <liv-button
      *ngIf="!isDesktop"
      [iconOnly]="true"
      rounded="lg"
      icon="assets/icons/close.svg"
      variant="ghost"
      (buttonClick)="onClose()"
    />
  </div>

  <ng-container *ngIf="isDesktop; else preview">
    <div class="bg-neutral-low-dark rounded-[40px] flex relative">
      <div class="bg-white m-[14px] rounded-[26px] w-full overflow-hidden">
        <iframe
          class="w-full h-full"
          [src]="previewUrl | safeTransform : 'RESOURCE'"
          (load)="onIframeLoaded()"
          [ngClass]="{
            'bg-gray-200 animate-pulse': isIframeLoading,
          }"
        ></iframe>
      </div>
      <div
        class="w-32 h-5 bg-neutral-low-dark rounded-br-xl rounded-bl-xl absolute top-3 left-[calc(50%_-_4rem)]"
      ></div>
    </div>
  </ng-container>

  <ng-template #preview>
    <iframe
      class="rounded-tr-[26px] rounded-br-[26px] w-full"
      [src]="previewUrl | safeTransform : 'RESOURCE'"
      (load)="onIframeLoaded()"
      [ngClass]="{
        'bg-gray-200 animate-pulse': isIframeLoading,
        'bg-white': !isIframeLoading
      }"
    ></iframe>
  </ng-template>

  <liv-button
    *ngIf="isDesktop"
    class="!px-5"
    [iconOnly]="true"
    rounded="lg"
    icon="assets/icons/close.svg"
    variant="ghost"
    (buttonClick)="onClose()"
  />
</div>
