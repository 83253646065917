<div class="modal-container">
  <liv-register-base-header-modal
    (buttonClose)="handleCloseModal()"
    title="Portfólio do Aluno"
    svgSrc="assets/icons/student-icon.svg"
    [closeModal]="false"
  ></liv-register-base-header-modal>

  <main class="modal-video-content">
    <liv-spinner *ngIf="!!isLoading" size="lg"> </liv-spinner>
    <div class="modal-video-content__box">
      <iframe
        #iframe
        [src]="
          'https://player.vimeo.com/video/821842543?h=e32c389a8b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
            | safeTransform : 'RESOURCE'
        "
        width="100%"
        height="100%"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
        (load)="handleLoadIframe()"
      ></iframe>
    </div>
  </main>
</div>
