import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'liv-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {
  @Input() src?: string;
  @Input() alt?: string;
  @Input() name?: string;
  @Input() size: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' = 'md';
  @Input() variant: 'circle' | 'square' = 'circle';

  initials: string;

  ngOnInit(): void {
    this.initials = this.getInitials();
  }

  onError(): void {
    this.src = null;
  }

  private getInitials(): string {
    if (!this.name) {
      return '';
    }

    const [firstName, ...lastNames] = this.name.split(' ');

    if (!lastNames.length) {
      return firstName.length > 1
        ? `${firstName.charAt(0).toUpperCase()}${firstName
            .charAt(1)
            .toUpperCase()}`
        : firstName.charAt(0).toUpperCase();
    }

    const lastName = lastNames[lastNames.length - 1];

    return `${firstName.charAt(0).toUpperCase()}${lastName
      .charAt(0)
      .toUpperCase()}`;
  }
}
