import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, catchError, of, switchMap, take, throwError } from 'rxjs';
import { Store } from '@ngxs/store';
import { WindowResizeService } from '../../../shell/services/window-resize.service';
import { GeneralService } from '../../services/general.service';
import { BookState } from '../../store/book/book.state';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'liv-preview-modal',
  templateUrl: './preview-modal.component.html',
  styleUrls: ['./preview-modal.component.scss']
})
export class PreviewModalComponent implements OnInit {
  @Input() coverUrl: string;

  public previewUrl = '';
  public isIframeLoading = true;
  public isDesktop = !this.windowResizeService.isMobileWidthSize;

  constructor(
    private ngbActiveModal: NgbActiveModal,
    private generalService: GeneralService,
    private windowResizeService: WindowResizeService,
    private toastService: ToastService,
    private store: Store
  ) {}

  ngOnInit(): void {
    const bookId = this.store.selectSnapshot(BookState.bookId);

    this.generalService
      .getExternalLink(bookId)
      .pipe(
        take(1),
        switchMap((externalLink) => {
          if (!externalLink.data?.attributes?.url) {
            return throwError(
              () => new Error('External link invalid or not found.')
            );
          }

          return of(externalLink.data.attributes.url);
        }),
        catchError(() => {
          this.toastService.error(
            'Houve um erro ao mostrar a pré-visualização'
          );
          return EMPTY;
        })
      )
      .subscribe((previewUrl) => {
        this.isIframeLoading = true;
        this.previewUrl = previewUrl;
      });
  }

  onIframeLoaded(): void {
    this.isIframeLoading = false;
  }

  onClose() {
    this.ngbActiveModal.close();
  }
}
