<div class="upload-file-content">
  <ng-container *ngIf="!file && !url; else showAvatar">
    <div
      class="dropzone"
      livDragDropFileUpload
      (click)="fileField.click()"
      (fileDropped)="onUpload($event)"
    >
      <div class="dropzone__info">
        <svg-icon src="assets/icons/gallery.svg"></svg-icon>
        <p class="text-center text-brand-primary-pure">
          {{ placeholderText }}
        </p>
        <span class="text-center text-brand-primary-pure"
          >Formatos suportados: JPEG, PNG e GIF</span
        >
      </div>
    </div>
    <input
      type="file"
      name="picture"
      accept="image/png,image/jpeg,image/gif"
      #fileField
      hidden
      (change)="onChange($event)"
    />
  </ng-container>

  <ng-template #showAvatar>
    <div class="picture-content max-h-full">
      <div class="picture-content__backdrop">
        <button
          class="icon-button-trash picture-content__trashButton"
          (click)="removePicture()"
        >
          <svg-icon src="assets/icons/trash.svg" svgClass="icon"></svg-icon>
        </button>
      </div>
      <img [src]="url | safeTransform : 'RESOURCE'" alt="" />
    </div>
  </ng-template>
</div>
