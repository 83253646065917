import {
  Component,
  Inject,
  InjectionToken,
  Injector,
  Type,
  inject
} from '@angular/core';
import { ModalService } from './modal.service';
import { DIALOG_DATA } from '@angular/cdk/dialog';

export interface ModalComponentOptions {
  isCloseButtonVisible?: boolean;
  size?: 'sm' | 'md' | 'lg';
  backgroundColor?: string;
}

interface ModalComponentData<T> {
  component: Type<T>;
  options?: ModalComponentOptions;
}

export type ModalData<T> = {
  [P in keyof T]: T[P];
};

export const MODAL_DATA = new InjectionToken<ModalData<unknown>>('MODAL_DATA');

@Component({
  selector: 'liv-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent<T> {
  protected component: Type<T> | null = null;
  protected options: ModalComponentOptions = {
    isCloseButtonVisible: true,
    size: 'md',
    backgroundColor: 'white'
  };

  protected dynamicComponentInjector: Injector | undefined;

  private _injector = inject(Injector);

  constructor(
    public modal: ModalService,
    @Inject(DIALOG_DATA) data: ModalComponentData<T>
  ) {
    const { component, ...rest } = data || {};

    this.component = component;
    this.options = { ...this.options, ...data.options };

    this.dynamicComponentInjector = Injector.create({
      providers: [{ provide: MODAL_DATA, useValue: rest }],
      name: 'MODAL_DATA',
      parent: this._injector
    });
  }

  get containerClass(): Record<string, boolean> {
    return {
      'max-w-[520px]': this.options.size === 'sm',
      'max-w-[768px]': this.options.size === 'md',
      'max-w-[1024px]': this.options.size === 'lg'
    };
  }

  onClose(): void {
    this.modal.close();
  }
}
