import { Dialog, DialogConfig, DialogRef } from '@angular/cdk/dialog';
import { Injectable, Type } from '@angular/core';

import { ModalComponent, ModalComponentOptions } from './modal.component';

type Newable = { new (...args: readonly unknown[]): unknown };
type AnyFn = (...args: unknown[]) => unknown;

type ClassProperties<C extends Newable> = {
  [K in keyof InstanceType<C> as InstanceType<C>[K] extends AnyFn
    ? never
    : K]: InstanceType<C>[K];
};

interface ModalProps<T> {
  data?: Partial<ClassProperties<T extends Newable ? T : never>>;
  options?: Pick<
    DialogConfig<T>,
    | 'ariaDescribedBy'
    | 'ariaLabel'
    | 'ariaLabelledBy'
    | 'ariaModal'
    | 'autoFocus'
    | 'direction'
  > &
    ModalComponentOptions & {
      disableBackdropClose?: boolean;
    };
}

@Injectable()
export class ModalService {
  private _ref: DialogRef;

  constructor(private dialog: Dialog) {}

  open<T>(component: Type<T>, props: ModalProps<Type<T>> = {}): DialogRef {
    const { data, options } = props || {};
    const {
      disableBackdropClose,
      isCloseButtonVisible,
      size,
      backgroundColor,
      ...defaultOptions
    } = options || {};

    const ref = this.dialog.open<T>(ModalComponent, {
      ...defaultOptions,
      disableClose: disableBackdropClose,
      panelClass: 'liv-modal-panel',
      data: {
        component,
        options: {
          isCloseButtonVisible: isCloseButtonVisible ?? true,
          size: size ?? 'md',
          backgroundColor: backgroundColor ?? 'white'
        },
        ...data
      }
    });

    this._ref = ref;

    return ref;
  }

  close(result?: unknown): void {
    this._ref.close(result);
  }
}
