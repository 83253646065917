import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DOMEvent } from '../../interfaces/dom-event';
import { FileService } from '../../utils/services/file/file.service';

@Component({
  selector: 'liv-upload-media-input',
  templateUrl: './upload-media-input.component.html',
  styleUrls: ['./upload-media-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadMediaInputComponent),
      multi: true
    }
  ]
})
export class UploadMediaInputComponent implements ControlValueAccessor {
  @Input() placeholderText = 'Insira aqui uma foto de toda a turma';

  private _file: File | null;
  private _url: string | null;

  constructor(private fileService: FileService) {}

  get file(): File | null {
    return this._file;
  }

  set file(obj: File) {
    if (obj !== this.file) {
      if (typeof obj === 'string') {
        this._url = obj;
        this._file = this.fileService.imageUrlToFile(obj, 'image.jpg');
      } else {
        this._file = obj;
        if (this._file) {
          this._url = this.fileService.createObjectURL(obj);
        }
      }
    }

    this.onChangeCb(this._file);
    this.onTouchedCb();
  }

  get url(): string | null {
    return this._url;
  }

  onChangeCb: (_: File | null) => void = () => {};
  onTouchedCb: () => void = () => {};

  writeValue(file: File | null): void {
    this.file = file;
  }

  registerOnChange(fn: (_: File | null) => void): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedCb = fn;
  }

  onUpload(files: File[] | null): void {
    if (files) {
      this.file = files[0];
    }
  }

  onChange(event: Event): void {
    const { files } = event.target as DOMEvent<HTMLInputElement>['target'];
    if (files) {
      this.file = files[0];
    }
  }

  removePicture(): void {
    this.file = null;

    if (this.url) {
      this.fileService.revokeObjectURL(this.url);
      this._url = null;
    }
  }
}
