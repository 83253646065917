import { Component, EventEmitter, Input, Output } from '@angular/core';

type ButtonClass = Readonly<Record<string, boolean>>;

@Component({
  selector: 'liv-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  private readonly prefixClass = 'liv-button';

  @Input() buttonText: string;
  @Input() size: 'sm' | 'md' | 'lg' | 'xl' = 'md';
  @Input() iconClass: string;
  @Input() variant:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'ghost'
    | 'danger'
    | 'link' = 'primary';
  @Input() rounded: 'sm' | 'md' | 'lg' = 'md';
  @Input()
  type: 'button' | 'submit' = 'button';
  @Input() fluid = false;
  @Input() disabled = false;
  @Input() isLoading = false;
  @Input() iconOnly = false;
  @Input() icon: string;
  @Input() iconPlacement: 'left' | 'right' = 'right';

  @Output() buttonClick = new EventEmitter();

  get variantClass(): ButtonClass {
    return {
      [`${this.prefixClass}--primary`]: this.variant === 'primary',
      [`${this.prefixClass}--secondary`]: this.variant === 'secondary',
      [`${this.prefixClass}--tertiary`]: this.variant === 'tertiary',
      [`${this.prefixClass}--ghost`]: this.variant === 'ghost',
      [`${this.prefixClass}--danger`]: this.variant === 'danger',
      [`${this.prefixClass}--link`]: this.variant === 'link'
    };
  }

  get iconPlacementClass(): ButtonClass {
    return {
      [`${this.prefixClass}-placement--left`]: this.iconPlacement === 'left',
      [`${this.prefixClass}-placement--right`]: this.iconPlacement === 'right'
    };
  }

  get sizeClass(): ButtonClass {
    return {
      [`${this.prefixClass}--sm`]: this.size === 'sm',
      [`${this.prefixClass}--md`]: this.size === 'md',
      [`${this.prefixClass}--lg`]: this.size === 'lg',
      [`${this.prefixClass}--xl`]: this.size === 'xl'
    };
  }

  get roundedClass(): ButtonClass {
    return {
      [`${this.prefixClass}-rounded--sm`]: this.rounded === 'sm',
      [`${this.prefixClass}-rounded--md`]: this.rounded === 'md',
      [`${this.prefixClass}-rounded--lg`]: this.rounded === 'lg'
    };
  }

  get dynamicClass(): ButtonClass {
    return {
      ...this.variantClass,
      ...this.iconPlacementClass,
      ...this.sizeClass,
      ...this.roundedClass,
      [`${this.prefixClass}--only-icon`]: this.iconOnly,
      [`${this.prefixClass}--is-loading`]: this.isLoading,
      [`${this.prefixClass}--fluid`]: this.fluid && !this.iconOnly
    };
  }
}
