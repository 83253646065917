import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  catchError,
  combineLatest,
  EMPTY,
  finalize,
  from,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
  throwError
} from 'rxjs';
import { Store } from '@ngxs/store';
import QRCode from 'qrcode';
import { BookState } from 'src/app/core/store/book/book.state';
import { TeacherState } from 'src/app/core/store/teacher/teacher.state';
import {
  SaveKibanaExternalLinkAction,
  SaveKibanaQRCodeDownloadAction,
  SaveKibanaShareMediaDownloadAction
} from 'src/app/core/store/kibana/kibana.actions';
import { GeneralService } from 'src/app/core/services/general.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { FileService } from 'src/app/shared/utils/services/file/file.service';

interface ShareMedia {
  ext: string;
  url: string;
  formats: {
    small: {
      url: string;
    };
  };
}

@Component({
  selector: 'liv-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss']
})
export class ShareModalComponent implements OnInit, OnDestroy {
  private isLoading = true;
  public externalLink = '';
  public qrCodeUrl = '';
  public shareMedia: ShareMedia;

  private destroy$ = new Subject<void>();

  private readonly QRCODE_CONFIG: QRCode.QRCodeToDataURLOptions = {
    width: 1080,
    margin: 8,
    type: 'image/jpeg',
    errorCorrectionLevel: 'L'
  };

  constructor(
    private ngbActiveModal: NgbActiveModal,
    private store: Store,
    private generalService: GeneralService,
    private toastService: ToastService,
    private fileService: FileService
  ) {}

  public get portfolioTitle() {
    const { name, serie } = this.store.selectSnapshot(BookState.grade);

    return `Portfólio ${name} - ${this.formatSerieName(serie.nome)}`;
  }

  ngOnInit(): void {
    combineLatest([this.getExternalLink(), this.getShareMedia()])
      .pipe(
        takeUntil(this.destroy$),
        tap(([externalLink, shareMedia]) => {
          this.externalLink = externalLink;
          this.shareMedia = shareMedia;
        }),
        switchMap(() => {
          return this.getQRCodeUrl();
        }),
        catchError(() => {
          this.toastService.error(
            'Houve um erro inesperado, por favor tente novamente mais tarde',
            {
              autoClose: true,
              position: 'top-right',
              duration: 3000
            }
          );
          this.ngbActiveModal.close();

          return EMPTY;
        }),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((qrCodeUrl) => {
        this.qrCodeUrl = qrCodeUrl;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getExternalLink() {
    const bookId = this.store.selectSnapshot(BookState.bookId);

    return this.generalService.getExternalLink(bookId).pipe(
      take(1),
      switchMap((externalLink) => {
        if (!externalLink.data?.attributes?.url) {
          return throwError(
            () => new Error('External link invalid or not found.')
          );
        }

        return of(externalLink.data.attributes.url);
      })
    );
  }

  getShareMedia(): Observable<ShareMedia> {
    const contentSlug = 'share-media';

    return this.generalService.getContent(contentSlug).pipe(
      take(1),
      switchMap((content) => {
        const { url, ext, formats } =
          content.data.attributes.image.data.attributes;

        return of({
          url,
          ext,
          formats: {
            small: {
              url: formats.small.url
            }
          }
        });
      })
    );
  }

  getQRCodeUrl(): Observable<string> {
    return from(QRCode.toDataURL(this.externalLink, this.QRCODE_CONFIG));
  }

  handleShareClick(): void {
    const portfolioId = this.store.selectSnapshot(BookState.bookId);
    const portfolioSeries = this.store.selectSnapshot(BookState.grade).serie
      .id_externo_strapi;

    from(navigator.clipboard.writeText(this.externalLink))
      .pipe(
        catchError(() => {
          this.toastService.error(
            'Erro ao copiar o link para a área de transferência.',
            {
              autoClose: true,
              position: 'top-right',
              duration: 3000
            }
          );

          return EMPTY;
        }),
        tap(() => {
          this.store.dispatch(
            new SaveKibanaExternalLinkAction({
              portfolioId,
              portfolioSeries,
              generatedLink: this.externalLink
            })
          );
        })
      )
      .subscribe(() => {
        this.toastService.success('Link copiado para a área de transferência', {
          autoClose: true,
          position: 'top-right',
          duration: 3000
        });
      });
  }

  handleDownloadContent(): void {
    this.generalService
      .downloadExternal(this.shareMedia.url)
      .pipe(
        take(1),
        catchError(() => {
          this.toastService.error('Erro ao baixar imagem.', {
            autoClose: true,
            position: 'top-right',
            duration: 3000
          });

          return EMPTY;
        }),
        tap(() => {
          this.store.dispatch(
            new SaveKibanaShareMediaDownloadAction({
              portfolioId: this.store.selectSnapshot(BookState.bookId),
              portfolioSeries: this.store.selectSnapshot(BookState.grade).serie
                .id_externo_strapi,
              externalProfessorId: this.store.selectSnapshot(
                TeacherState.teacher
              ).externalProfessorId,
              escola: this.store.selectSnapshot(BookState.content).attributes
                .escola
            })
          );
        })
      )
      .subscribe((contentBlob) =>
        this.downloadImage(
          this.fileService.createObjectURL(contentBlob),
          `Confira este Portfólio!${this.shareMedia.ext}`
        )
      );
  }

  handleDownloadQRCode(): void {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = 1080;
    canvas.height = 1155;

    this.drawQRCode(ctx, canvas.width, canvas.height);

    this.store.dispatch(
      new SaveKibanaQRCodeDownloadAction({
        portfolioId: this.store.selectSnapshot(BookState.bookId),
        portfolioSeries: this.store.selectSnapshot(BookState.grade).serie
          .id_externo_strapi,
        externalProfessorId: this.store.selectSnapshot(TeacherState.teacher)
          .externalProfessorId,
        escola: this.store.selectSnapshot(BookState.content).attributes.escola
      })
    );

    this.downloadImage(canvas.toDataURL(), `${this.portfolioTitle}.jpg`);
  }

  handleCloseModal(): void {
    this.ngbActiveModal.close();
  }

  private downloadImage(url: string, filename: string): void {
    const anchorElement = document.createElement('a');

    anchorElement.href = url;
    anchorElement.download = filename;

    document.body.appendChild(anchorElement);

    anchorElement.click();

    document.body.removeChild(anchorElement);
  }

  private formatSerieName(name: string): string {
    const serieMap = {
      Fundamental: '',
      Anos: 'anos'
    };

    return name
      .replace(/\b(Fundamental|Anos)\b/, (match) => serieMap[match] as string)
      .trim();
  }

  private drawQRCode(
    ctx: CanvasRenderingContext2D,
    width: number,
    height: number
  ) {
    const img = document.querySelector<HTMLImageElement>('#qrCode');

    ctx.fillStyle = '#fff';
    ctx.fillRect(0, 0, width, height);

    const qrCodeWidth = 1080;
    const qrCodeHeight = 1080;
    const qrCodeX = 0;
    const qrCodeY = height - qrCodeHeight;

    ctx.drawImage(img, qrCodeX, qrCodeY, qrCodeWidth, qrCodeHeight);

    ctx.fillStyle = '#000';
    ctx.font = '500 52px Roboto';
    ctx.textAlign = 'center';

    const textX = width / 2;
    const textY = 140;

    ctx.fillText(this.portfolioTitle, textX, textY);
  }
}
