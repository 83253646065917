import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'liv-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  public isLoading = true;
  constructor(private ngbActiveModal: NgbActiveModal) {}

  handleCloseModal(): void {
    this.ngbActiveModal.close();
  }

  handleLoadIframe(): void {
    this.isLoading = false;
  }
}
