import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiGatewayService, IRequestOptions } from './api/api-gateway.service';
import {
  Content,
  ExternalLink
} from 'src/app/shell/models/portfolio-book.model';
import { Observable } from 'rxjs';
import { LivPortfolioResponseProtocol } from '../models/liv-portfolio-response-protocol.model';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  constructor(
    private httpClient: HttpClient,
    private portfolioApi: ApiGatewayService
  ) {}

  getExternalLink(
    bookId: number
  ): Observable<LivPortfolioResponseProtocol<ExternalLink>> {
    return this.portfolioApi.get<ExternalLink>(`/livros/linkExterno/${bookId}`);
  }

  getContent(slug: string): Observable<LivPortfolioResponseProtocol<Content>> {
    return this.portfolioApi.get<Content>(`/content/${slug}`);
  }

  downloadExternal(
    endpoint: string,
    options: IRequestOptions = {}
  ): Observable<Blob> {
    const url = encodeURI(endpoint);
    return this.httpClient.get<Blob>(url, {
      ...options,
      responseType: 'blob' as 'json'
    });
  }
}
