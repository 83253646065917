<span class="spinner">
  <svg
    width="24px"
    height="24px"
    [ngClass]="{
      'spinner__svg--sm': size === 'sm',
      'spinner__svg--md': size === 'md',
      'spinner__svg--lg': size === 'lg'
    }"
    viewBox="0 0 66 66"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      class="spinner__svg__path"
      fill="none"
      stroke-width="6"
      stroke-linecap="round"
      cx="33"
      cy="33"
      r="30"
    ></circle>
  </svg>
</span>
