import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalService } from '../../../ui/components/modal/modal.service';

@Component({
  selector: 'liv-register-base-header-modal',
  templateUrl: './register-base-header-modal.component.html',
  styleUrls: ['./register-base-header-modal.component.scss']
})
export class RegisterBaseHeaderModalComponent {
  @Input() title: string;
  @Input() showIcon = true;
  @Input() svgSrc = '/assets/icons/student-icon.svg';
  @Input() closeModal = true;
  @Output() buttonClose = new EventEmitter();

  public showHelpButton = false;

  constructor(private modal: ModalService) {}

  onClose(): void {
    this.buttonClose.emit();
    if (this.closeModal) {
      this.modal.close();
    }
  }
}
