import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { BookState } from 'src/app/core/store/book/book.state';
import { convertHexToRGBA } from '../helpers/convert-colors';
@Pipe({
  name: 'backgroundColor'
})
export class BackgroundColorPipe implements PipeTransform {
  constructor(private store: Store) {}
  transform(
    color: string,
    opacity?: string,
    elseBackgroundType?: 'brand' | 'brandLight'
  ): string {
    if (color) {
      return convertHexToRGBA(color, opacity ? Number(opacity) : 0.1);
    }
    const colors = this.store.selectSnapshot(BookState.colors);
    return elseBackgroundType === 'brand' ? colors.brand : colors.brand_light;
  }
}
