import {
  KibanaCustomTeacherShareModalOpen,
  KibanaCustomTeacherQRCodeDownload,
  KibanaPortfolioExternalLink,
  KibanaPortfolioModel,
  KibanaPortfolioRecordModel,
  KibanaPortfolioRegisterPagesEdit,
  KibanaCustomTeacherShareMediaDownload,
  KibanaNewCreativeSpace,
  KibanaClassSelectionPayload
} from './kibana.models';

export class SaveKibanaGeneralAction {
  static readonly type = '[Kibana] Save Kibana General';
  constructor(public payload: KibanaPortfolioModel) {}
}

export class SaveKibanaRegisterAction {
  static readonly type = '[Kibana] Save Kibana Register';
  constructor(public payload: KibanaPortfolioRecordModel) {}
}

export class SaveKibanaRegisterPagesEditAction {
  static readonly type = '[Kibana] Save Register Pages Edit';
  constructor(public payload: KibanaPortfolioRegisterPagesEdit) {}
}

export class SaveKibanaExternalLinkAction {
  static readonly type = '[Kibana] Save External Link';
  constructor(public payload: KibanaPortfolioExternalLink) {}
}

export class SaveKibanaQRCodeDownloadAction {
  static readonly type = '[Kibana] Save QRCode Download';
  constructor(public payload: KibanaCustomTeacherQRCodeDownload) {}
}

export class SaveKibanaShareMediaDownloadAction {
  static readonly type = '[Kibana] Save Share Media Download';
  constructor(public payload: KibanaCustomTeacherShareMediaDownload) {}
}

export class SaveKibanaShareModalOpenAction {
  static readonly type = '[Kibana] Save Share Modal Open';
  constructor(public payload: KibanaCustomTeacherShareModalOpen) {}
}

export class SaveKibanaNewCreativeSpaceAction {
  static readonly type = '[Kibana] Save New Creative Space';
  constructor(public payload: KibanaNewCreativeSpace) {}
}

export class SaveKibanaClassSelectionAction {
  static readonly type = '[Kibana] Save Class Selection';
  constructor(public payload: KibanaClassSelectionPayload) {}
}
